class Config {
  readonly webhook_url: string

  constructor() {
    const webhook_url = process.env.REACT_APP_WEBHOOK_URL
    this.webhook_url = webhook_url !== undefined ? webhook_url : ''
  }
}

const config = new Config()

export default config
