import styled from 'styled-components'
import theme from '../../themes'

const Div = styled.div`
  background-color: ${theme.color.background.accent};
  color: ${theme.color.primary.accent};
  font-family: ${theme.font.header};
  font-size: 36px;
  padding: 20px;
  width: calc(100% - 40px);
`

export default function NavBar() {
  return <Div>Fenrir Server Settings</Div>
}
