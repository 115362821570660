import { FunctionComponent } from 'react'
import styled from 'styled-components'

const Div = styled.div`
  margin: 20px;
`

const Content: FunctionComponent<{}> = ({ children }) => {
  return <Div>{children}</Div>
}

export default Content
