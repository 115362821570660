import { FunctionComponent } from 'react'
import RestartButton from '../../components/button/restart'
import config from '../../../domain/core/config'

const Public: FunctionComponent<{}> = () => {
  const url = `${config.webhook_url}/pz/restart`

  return (
    <>
      <h1>Project Zomboid</h1>
      <RestartButton url={url}>Restart PZ Server</RestartButton>
    </>
  )
}

export default Public
