import Theme from './theme'

const theme: Theme = {
  color: {
    background: {
      faded: '#424242',
      normal: '#313131',
      accent: '#212121',
    },
    primary: {
      faded: '#d32f2f',
      normal: '#f44336',
      accent: '#ff1744',
    },
    secondary: {
      faded: '#303f9f',
      normal: '#3f51b5',
      accent: '#3d5afe',
    },
    text: {
      faded: '#e0e0e0',
      normal: '#f5f5f5',
      accent: '#fafafa',
    },
    button: {
      primary: {
        normal: '',
        hover: '',
        active: '',
        disabled: '',
      },
      secondary: {
        normal: '',
        hover: '',
        active: '',
        disabled: '',
      },
      accept: {
        normal: '#00c853',
        hover: '',
        active: '',
        disabled: '',
      },
      warning: {
        normal: '#d50000',
        hover: '#d43535',
        active: '#bd0202',
        disabled: '#ababab',
      },
    },
  },
  font: {
    text: "'Roboto Slab', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    header:
      "'Roboto Condensed', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    headings: '',
  },
}

export default theme
