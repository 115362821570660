import React from 'react'
import NavBar from './components/nav-bar'
import styled from 'styled-components'
import theme from './themes'
import Content from './components/content'
import PublicPage from './pages/public'

const Div = styled.div`
  color: ${theme.color.text.normal};
`

function App() {
  return (
    <Div className="App">
      <NavBar />
      <Content>
        <PublicPage />
      </Content>
    </Div>
  )
}

export default App
