import React, { FunctionComponent, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import theme from '../../themes'

interface RestartButtonProps {
  readonly url: string
  readonly payload?: any
}

const RestartButton: FunctionComponent<RestartButtonProps> = ({
  children,
  url,
  payload,
}) => {
  const [clickable, setClickable] = useState(true)

  const restartAnimation = keyframes`
    from { background-color: ${theme.color.button.accept.normal}; }
    to { background-color: ${theme.color.button.warning.normal}; }
  `

  const delaySeconds = 5

  const animation = css`
    animation: ${restartAnimation} ${delaySeconds}s
      cubic-bezier(1, 0, 0.95, 0.1) 1;
  `

  const Button = styled.button`
    width: 300px;
    padding: 20px;
    font-size: 20px;
    color: ${theme.color.text.accent};
    font-weight: 700;
    font-family: ${theme.font.headings};
    background-color: ${theme.color.button.warning.normal};
    border: 0;
    border-radius: 15px;

    ${clickable ? '' : animation}

    &:hover {
      background-color: ${theme.color.button.warning.hover};
    }
    &:active {
      background-color: ${theme.color.button.warning.active};
    }
  `

  function onClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    if (!clickable) {
      return
    }
    setClickable(false)

    fetch(url, {
      method: 'POST',
      body: payload,
    }).then((response) => {
      console.log(response)
    })

    setTimeout(() => {
      setClickable(true)
    }, delaySeconds * 1000)
  }

  return <Button onClick={onClick}>{children}</Button>
}

export default RestartButton
